import { z } from 'zod';

const dateSchema = z.date()
  .or(z.literal(null))
  .refine((date) => date !== null, "Поле должно быть формата 'дд:мм:гггг' и не пустым");
// eslint-disable-next-line import/prefer-default-export
export const reportsSchema = z.object({
  statisticsFrom: dateSchema,
  statisticsTo: dateSchema,
  statisticsPeriod: z.number()
    .int()
    .min(1, 'Период должен быть не менее 1')
    .max(3, 'Период должен быть не более 3'),
  unloadingFrom: dateSchema,
  unloadingTo: dateSchema,
  unloadingKtgFrom: dateSchema,
  unloadingKtgTo: dateSchema,
});
